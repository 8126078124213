import {Monorail} from '@shopify/monorail';
import {MonorailRequestError} from '@shopify/monorail/lib/producers/producer-errors';
import {CompositeMonorailEvent} from '@shopify/monorail/lib/events/events';
import {InstallmentsPrequalPageType} from 'types/paymentTerms';

import Bugsnag from '../bugsnag';
import {
  InstallmentsBannerContent,
  InstallmentsBannerType,
  BannerTemplateCodeSignature,
  ModalType,
  ModalUserAction,
} from '../../types';
import {MonorailProducerError} from '../utils';
import {APP_VERSION} from '../constants';

import {getTrekkieAttributes} from './trekkie';
import {
  LoginWithShopSdkPageImpressionEventProps,
  MonorailSchema,
  LoginWithShopSdkErrorEventProps,
  PageImpressionTracked,
  LoginWithShopSdkUserActionEventProps,
} from './types';

declare global {
  interface Window {
    Shopify: any;
    ShopifyAnalytics?: any;
    analytics?: any;
    trekkie?: any;
  }
}

export const monorailProducer =
  // eslint-disable-next-line no-process-env
  process.env.NODE_ENV === 'production'
    ? Monorail.createHttpProducer({production: true})
    : Monorail.createLogProducer({debugMode: true});

export default class MonorailTracker {
  protected readonly _elementName: string;
  protected readonly _analyticsTraceId: string | undefined;
  protected readonly _shopifyPermanentDomain = window.Shopify?.shop ?? '';
  protected readonly _shopId?: number;
  protected readonly _checkoutVersion?: string;
  protected _flowVersion: string;
  private readonly _flow: string;

  private _impressionTracked = false;
  private _bannerImpressionTracked: Record<string, boolean> = {};
  private _bannerPrequalInteractionTracked = false;
  private _modalActionTracker: Record<string, boolean> = {};
  private _shopLoginFirstTimeRenderTracked: Record<string, boolean> = {};
  private _invalidBannerMetadataTracked = false;
  private _pageImpressionTracked: PageImpressionTracked = {
    AUTHORIZE_MODAL: false,
    CLASSIC_CUSTOMER_ACCOUNTS_ACCOUNT_PAGE: false,
    CLASSIC_CUSTOMER_ACCOUNTS_CREATE_ACCOUNT_PAGE: false,
    CLASSIC_CUSTOMER_ACCOUNTS_LOGIN_PAGE: false,
    COMPONENT_LOADED_FOLLOWING: false,
    COMPONENT_LOADED_NOT_FOLLOWING: false,
    CONTINUE_WITH_SHOP_PAGE: false,
    DISCOUNT_SAVE_CONFIRMATION_PAGE: false,
    DISCOUNT_SHOWN: false,
    FOLLOWING_GET_SHOP_APP_CTA: false,
    FOLLOW_BUTTON_SHOWN_IN_VIEWPORT: false,
    PARTNER_EMAIL_INPUT_SHOWN: false,
    SDK_HAS_LOADED_INITIAL_PAGE: false,
    TEXT_MARKETING_SIGN_UP: false,
    TEXT_MARKETING_CONFIRMED_PAGE: false,
    TEXT_MARKETING_DECLINED_PAGE: false,
  };

  private _prequalPopupPageImpressionTracked: Record<string, boolean> = {};

  private _initTimestamp: number;

  /**
   * @param {object} params The parameters object.
   * @param {string} params.elementName The name of the element (e.g. `shop-pay-button`, `shop-login-button`, `shopify-payment-terms`).
   * @param {string} params.analyticsTraceId A UUID that can correlate all analytics events fired for the same user flow. I.e. Could be
   * @param {string} params.flow The SDK flow, eg. ('discount' or 'follow').
   * used to correlate events between Shop JS and Pay for the Shop Login flow.
   * @param {string} params.flowVersion The version of the Sign in with Shop flow (eg. "sign_in" or "sign_up")
   * @param {number} [params.shopId] The numeric id of the shop.
   * @param {string} [params.checkoutVersion] A checkout version such as "classic" or "shop_pay_external"
   */
  constructor({
    elementName,
    analyticsTraceId,
    flow = '',
    flowVersion = 'unspecified',
    shopId,
    checkoutVersion,
  }: {
    elementName: string;
    analyticsTraceId?: string;
    flow?: string;
    flowVersion?: string;
    shopId?: number;
    checkoutVersion?: string;
  }) {
    this._elementName = elementName;
    this._flow = flow;
    this._analyticsTraceId = analyticsTraceId;
    this._initTimestamp = new Date().getTime();
    this._flowVersion = flowVersion;
    this._checkoutVersion = checkoutVersion;
    this._shopId = shopId;
  }

  /**
   * Fired when a component from shop-js is mounted on the page.
   * @param {InstallmentsBannerType} elementType The type element that emitted the event. Currently supported only on `shopify-payment-terms`.
   */
  async trackElementImpression(
    elementType?: InstallmentsBannerType,
  ): Promise<void> {
    if (this._impressionTracked) {
      return;
    }
    this._impressionTracked = true;

    const trekkieAttributes = await getTrekkieAttributes(
      'uniqToken',
      'visitToken',
      'microSessionId',
      'microSessionCount',
      'shopId',
      'themeId',
      'themeCityHash',
      'contentLanguage',
      'referer',
    );

    const payload = {
      ...trekkieAttributes,
      elementType,
      elementName: this._elementName,
      shopJsVersion: APP_VERSION,
    };

    produceMonorailEvent(
      {
        schemaId: MonorailSchema.UiImpression,
        payload,
      },
      trekkieAttributes,
      () => {
        this._impressionTracked = false;
      },
    );
  }

  /**
   * Fired when the page need to be tracked.
   * @param {object} params The parameters object.
   * @param {object} params.shopAccountUuid The shop account uuid.
   * @param {string} params.apiKey The API key of the app.
   * @param {object} params.page The page's impression to track.
   */
  async trackPageImpression({
    shopAccountUuid,
    apiKey,
    page,
  }: LoginWithShopSdkPageImpressionEventProps): Promise<void> {
    if (this._pageImpressionTracked[page]) {
      return;
    }
    this._pageImpressionTracked[page] = true;

    const trekkieAttributes = await getTrekkieAttributes(
      'uniqToken',
      'visitToken',
      'isPersistentCookie',
      'path',
      'customerId',
    );

    const payload = {
      ...trekkieAttributes,
      shopPermanentDomain: this._shopifyPermanentDomain,
      flow: this._flow,
      flowVersion: this._flowVersion,
      sdkVersion: APP_VERSION,
      analyticsTraceId: this._analyticsTraceId!,
      pageName: page,
      ...(apiKey && {apiKey}),
      ...(shopAccountUuid && {shopAccountUuid}),
    };

    produceMonorailEvent(
      {
        schemaId: MonorailSchema.LoginWithShopSdkPageImpression,
        payload,
      },
      trekkieAttributes,
      () => {
        this._pageImpressionTracked[page] = false;
      },
    );
  }

  /**
   * Fired when the Login with Shop UI has finished its initial render (including the UI rendered by Pay). This is Unique
   * to the Login with Shop flows because they execute a network request to Pay before rendering the UI.
   * @param {string} eventName The name of the event. Defaults to the flow version.
   * @param {number} startTime The start time to use to calculate `duration`. If not provided it will default to the
   * moment the class was instantiated.
   */
  async trackShopLoginFirstTimeRender(
    eventName: string = this._flowVersion,
    startTime: number = this._initTimestamp,
  ): Promise<void> {
    if (this._shopLoginFirstTimeRenderTracked[eventName]) {
      return;
    }
    this._shopLoginFirstTimeRenderTracked[eventName] = true;

    const timestamp = new Date().getTime();
    const duration = timestamp - startTime;

    const trekkieAttributes = await getTrekkieAttributes('shopId');

    const payload = {
      analyticsTraceId: this._analyticsTraceId,
      duration,
      ...trekkieAttributes,
      shopLoginVersion: eventName,
      url: window.location.href,
      userAgent: navigator.userAgent,
    };

    produceMonorailEvent(
      {
        schemaId: MonorailSchema.ShopLoginFirstTimeRender,
        payload,
      },
      trekkieAttributes,
      () => {
        this._shopLoginFirstTimeRenderTracked[eventName] = false;
      },
    );
  }

  trackShopPayLoginWithShopSdkUserAction({
    apiKey,
    userAction,
  }: LoginWithShopSdkUserActionEventProps) {
    const payload = {
      ...(apiKey && {apiKey}),
      flow: this._flow,
      flowVersion: this._flowVersion,
      sdkVersion: APP_VERSION,
      analyticsTraceId: this._analyticsTraceId!,
      ...(this._checkoutVersion && {checkoutVersion: this._checkoutVersion}),
      ...(this._shopId && {shopId: this._shopId}),
      shopPermanentDomain: this._shopifyPermanentDomain,
      userAction,
    };

    produceMonorailEvent({
      schemaId: MonorailSchema.LoginWithShopSdkUserAction,
      payload,
    });
  }

  /**
   * Fired when the API emits error events
   * @param {object} params The parameters object.
   * @param {string} params.apiKey The API key of the app.
   * @param {string} params.errorCode The error code emitted by the API.
   * @param {string} params.errorMessage The error message emitted by the API.
   */
  trackShopPayLoginWithSdkErrorEvents({
    apiKey,
    errorCode,
    errorMessage,
  }: LoginWithShopSdkErrorEventProps) {
    const payload = {
      apiKey,
      flow: this._flow,
      flowVersion: this._flowVersion,
      sdkVersion: APP_VERSION,
      analyticsTraceId: this._analyticsTraceId,
      shopPermanentDomain: this._shopifyPermanentDomain,
      errorCode,
      errorMessage,
    };

    produceMonorailEvent({
      schemaId: MonorailSchema.LoginWithShopSdkErrorEvents,
      payload,
    });
  }

  /**
   * Fired when the shopify-installments-modal component from shop-js has opened for the Cart or
   * a Product Variant.
   * @param {InstallmentsBannerType} origin The origin page type of this modal.
   * @param {string} modalToken A token generated by the modal to uniquely identify the modal and actions taken by the user.
   * @param {ModalType} eligibleSpiPlanType The type of modal that emitted the event.
   * @param {string} spiPlanDetails A stringified JSON array with details about sample installments plans shown on the modal.
   * @param {number} variantId A Unique identifier for a product variant. Is supported for Product Variant pages.
   * @param {string} price The price that is shown on the installments modal. Represents the total price of all
   *   items in the cart if the impression is from the `cart` page and the price of the product variant if the impression
   *   is from a `product` page.
   * @param {string} cartPermalink The permalink used to create the cart if the modal has a 'Continue to Checkout' button available.
   */
  async trackModalOpened(
    origin: InstallmentsBannerType,
    modalToken: string,
    eligibleSpiPlanType: ModalType,
    spiPlanDetails: string,
    variantId?: number,
    price?: string,
    cartPermalink?: string,
  ): Promise<void> {
    let modalActionIdentifier: string;

    if (origin === InstallmentsBannerType.Cart) {
      modalActionIdentifier = `${origin}-open`;
    } else {
      modalActionIdentifier = `${modalToken}-open`;
    }

    if (this._modalActionTracker[modalActionIdentifier]) {
      return;
    }
    this._modalActionTracker[modalActionIdentifier] = true;

    const trekkieAttributes = await getTrekkieAttributes(
      'uniqToken',
      'visitToken',
      'microSessionId',
      'microSessionCount',
      'shopId',
      'currency',
    );

    const payload = {
      ...trekkieAttributes,
      origin,
      modalToken,
      eligibleSpiPlanType,
      price,
      cartPermalink,
      spiPlanDetails,
      variantId,
      shopJsVersion: APP_VERSION,
    };

    produceMonorailEvent(
      {
        schemaId: MonorailSchema.InstallmentsModalOpened,
        payload,
      },
      trekkieAttributes,
      () => {
        this._modalActionTracker[modalActionIdentifier] = false;
      },
    );
  }

  /**
   * Fired when a user makes a meaningful action on the shopify-installments-modal.
   * @param {string} modalToken A token generated by the modal to uniquely identify the modal and actions taken by the user.
   * @param {ModalUserAction} action The action taken by the user.
   * @param {string} cartPermalink The permalink used to create the cart if the modal has a 'Continue to Checkout' button available.
   */
  async trackModalAction(
    modalToken: string,
    action: ModalUserAction,
    cartPermalink?: string,
  ): Promise<void> {
    const modalActionIdentifier = `${modalToken}-${action}`;
    if (this._modalActionTracker[modalActionIdentifier]) {
      return;
    }
    this._modalActionTracker[modalActionIdentifier] = true;

    const trekkieAttributes = await getTrekkieAttributes(
      'uniqToken',
      'visitToken',
      'microSessionId',
      'microSessionCount',
      'shopId',
    );

    const payload = {
      ...trekkieAttributes,
      modalToken,
      action,
      cartPermalink,
      shopJsVersion: APP_VERSION,
    };

    produceMonorailEvent(
      {
        schemaId: MonorailSchema.InstallmentsModalUserAction,
        payload,
      },
      trekkieAttributes,
      () => {
        this._modalActionTracker[modalActionIdentifier] = false;
      },
    );
  }

  /**
   * Fired when the installment banner component is mounted on the page.
   * @param {InstallmentsBannerType} origin The origin page type of this banner.
   * @param {InstallmentsBannerContent} bannerContent The content of the banner that was shown to the user.
   * @param {boolean} eligible Whether the user's product or cart is currently eligible for installments based on price.
   * @param {BannerTemplateCodeSignature} bannerTemplateCodeSignature The banner implementation being used on the page.
   * @param {boolean} hasPrequalLink Whether the banner includes prequal flow. True if banner includes CTA link
    to start prequal flow
   * @param {string} price The price that is shown on the installments modal. Represents the total price of all
   *   items in the cart if the impression is from the `cart` page and the price of the product variant if the impression
   *   is from a `product` page.
   * @param {number} variantId A Unique identifier for a product variant. Is supported for Product Variant pages exclusively.
   */
  async trackInstallmentsBannerImpression(
    origin: InstallmentsBannerType,
    bannerContent: InstallmentsBannerContent,
    eligible: boolean,
    bannerTemplateCodeSignature: BannerTemplateCodeSignature,
    hasPrequalLink: boolean,
    price?: string,
    variantId?: number,
  ): Promise<void> {
    const identifier = variantId ? String(variantId) : 'cart';
    if (this._bannerImpressionTracked[identifier]) {
      return;
    }
    this._bannerImpressionTracked[identifier] = true;

    const trekkieAttributes = await getTrekkieAttributes(
      'uniqToken',
      'visitToken',
      'shopId',
      'microSessionId',
      'contentLanguage',
      'currency',
    );

    const payload = {
      ...trekkieAttributes,
      origin,
      bannerContent,
      eligible,
      bannerTemplateCodeSignature,
      price,
      shopJsVersion: APP_VERSION,
      hasPrequalLink,
      // The event triggered in ShopPayBanner won't contain an analyticsTraceId.
      // Because this ID is only relevant in the prequalification flow,
      // to ensure schema validation, we assign it an empty string in this context."
      analyticsTraceId: this._analyticsTraceId! || '',
    };

    produceMonorailEvent(
      {
        schemaId: MonorailSchema.InstallmentsBannerImpression,
        payload,
      },
      trekkieAttributes,
      () => {
        this._bannerImpressionTracked[identifier] = false;
      },
    );
  }

  /**
   * Fired whenever the buyer clicks the prequal link in the banner on the page, To track click of the prequal link in the banner on the product page
   * @param {number} sellerId the Installments identifier for the shop
   * @param {string} pageType The page that the buyer reached.
   */
  async trackInstallmentsPrequalPopupPageImpression(
    sellerId: number | undefined,
    pageType: InstallmentsPrequalPageType,
  ): Promise<void> {
    if (this._prequalPopupPageImpressionTracked[pageType]) {
      return;
    }
    this._prequalPopupPageImpressionTracked[pageType] = true;

    const payload = {
      analyticsTraceId: this._analyticsTraceId!,
      sellerId,
      pageType,
    };

    produceMonorailEvent(
      {
        schemaId: MonorailSchema.InstallmentsPrequalPopupPageImpression,
        payload,
      },
      undefined,
      () => {
        this._prequalPopupPageImpressionTracked[pageType] = false;
      },
    );
  }

  /**
   * Fired when the installment banner component is mounted on the page and the metadata being supplied is missing a required attribute.
   * @param {InstallmentsBannerType} origin The type element that emitted the event.
   * @param {string} metadata The data currently passed into the installment banner as the shopify-meta attribute.
   */
  async trackInvalidInstallmentBannerMetadata(
    origin: InstallmentsBannerType,
    metadata: string,
  ): Promise<void> {
    if (this._invalidBannerMetadataTracked) {
      return;
    }
    this._invalidBannerMetadataTracked = true;

    const trekkieAttributes = await getTrekkieAttributes(
      'uniqToken',
      'visitToken',
      'microSessionId',
      'microSessionCount',
      'shopId',
    );

    const payload = {
      ...trekkieAttributes,
      origin,
      metadata,
      shopJsVersion: APP_VERSION,
    };

    produceMonorailEvent(
      {
        schemaId: MonorailSchema.InstallmentsInvalidMetadata,
        payload,
      },
      trekkieAttributes,
      () => {
        this._invalidBannerMetadataTracked = false;
      },
    );
  }

  /**
   * Fired whenever the buyer clicks the prequal link in the banner on the page, To track click of the prequal link in the banner on the product page
   * @param {InstallmentsBannerType} origin The type element that emitted the event.
   * @param {InstallmentsBannerContent} bannerContent The content of the banner that was shown to the user.
   * @param {boolean} eligible Whether the user's product or cart is currently eligible for installments based on price.
   * @param {string} price The price that is shown on the installments modal. Represents the total price of all
   * @param {boolean} prequalLinkClicked  True if the user clicked the prequal link
   */
  async trackInstallmentsBannerPrequalInteraction(
    origin: InstallmentsBannerType,
    bannerContent: InstallmentsBannerContent,
    eligible: boolean,
    price: string,
    prequalLinkClicked: boolean,
  ): Promise<void> {
    if (this._bannerPrequalInteractionTracked) {
      return;
    }
    this._bannerPrequalInteractionTracked = true;

    const trekkieAttributes = await getTrekkieAttributes(
      'uniqToken',
      'visitToken',
      'shopId',
      'microSessionId',
      'contentLanguage',
      'currency',
    );

    const payload = {
      ...trekkieAttributes,
      origin,
      bannerContent,
      eligible,
      price,
      shopJsVersion: APP_VERSION,
      prequalLinkClicked,
      analyticsTraceId: this._analyticsTraceId!,
    };

    produceMonorailEvent(
      {
        schemaId: MonorailSchema.InstallmentsBannerPrequalInteraction,
        payload,
      },
      trekkieAttributes,
      () => {
        this._bannerPrequalInteractionTracked = false;
      },
    );
  }
}

/**
 *
 * @param {CompositeMonorailEvent} monorailEvent The monorail event to be produced
 * @param {object} trekkieAttributes The attributes retrieved from trekkie. If provided, they will be merged with the monorail event payload.
 * If empty but defined they will block the monorail event from being produced.
 * @param {Function} onError A function to be called in the case that an error is thrown while producing a monorail event
 */
function produceMonorailEvent(
  monorailEvent: CompositeMonorailEvent,
  trekkieAttributes?: object,
  onError?: (obj?: object) => void,
): void {
  if (trekkieAttributes && !Object.keys(trekkieAttributes).length) {
    // if trekkie attributes are provided but the object is empty then we don't want to send the event
    // since we knew it will not have a valid schema
    onError?.({message: 'trekkie attributes are empty'});
    return;
  }

  monorailEvent.payload = Object.assign(
    monorailEvent.payload,
    trekkieAttributes,
  );
  monorailProducer.produce(monorailEvent).catch((error) => {
    onError?.(error);

    if (isUsefulError(error)) {
      const caughtError =
        error instanceof Error
          ? error
          : new MonorailProducerError(String(error));
      Bugsnag.notify(caughtError);
    }
  });
}

/**
 * Silence network request errors, which are likely caused by browser ad blockers.
 * See: https://github.com/Shopify/shop-identity/issues/1664
 * https://github.com/Shopify/shop-identity/issues/2008
 * @param {any} error The error to check
 * @returns {boolean} whether the error is useful or not
 */
function isUsefulError(error: any): boolean {
  return (
    !(error instanceof MonorailRequestError) &&
    !error?.message?.includes('Invalid agent:')
  );
}
