import {DefaultComponentAnalyticsContext} from '../../../constants/loginDefault';

// List of contexts for the Default component which serve copy from ShopJS instead of Pay.
// These contexts should include the "hide-copy" param to Pay's authorize endpoint.
const CONTEXTS_SERVING_COPY_FROM_SHOP_JS = [
  DefaultComponentAnalyticsContext.Default,
  DefaultComponentAnalyticsContext.CheckoutExtension,
  DefaultComponentAnalyticsContext.ClassicCustomerAccounts,
  DefaultComponentAnalyticsContext.PaymentRequest,
];

/**
 * Returns whether or not the copy in Pay is hidden for the given context.
 * @param {DefaultComponentAnalyticsContext} context The context of the component, eg. 'loginWithShopSelfServe'.
 * @returns {boolean} Whether or not the copy in Pay is hidden for the given context.
 */
export function shouldHidePayCopy(
  context: DefaultComponentAnalyticsContext,
): boolean {
  return CONTEXTS_SERVING_COPY_FROM_SHOP_JS.includes(context);
}
