import {climbDomTree} from './utils';

type Colors = Record<string, string>;
export const colors: Colors = {
  brand: '#5a31f4',
  white: '#fff',
  black: '#000',
  warning: '#d02e11',
  close: '#707070',
  foregroundSecondary: '#3F454D',
  backgroundSubdued: '#F2F3F5',
  backgroundError: '#FFECE9',
  error: '#D02E11',
};

/**
 * Converts HEX color string into RGB array.
 * @param {string} hex string e.g. #FFF or #CAFEFE
 * @returns {Array<number>} like [255, 255, 255] or [202, 254, 254]
 */
function hexToRGB(hex: string): number[] {
  let red = 0;
  let green = 0;
  let blue = 0;

  // 3 digits
  if (hex.length === 4) {
    red = Number(`0x${hex[1]}${hex[1]}`);
    green = Number(`0x${hex[2]}${hex[2]}`);
    blue = Number(`0x${hex[3]}${hex[3]}`);

    // 6 digits
  } else if (hex.length === 7) {
    red = Number(`0x${hex[1]}${hex[2]}`);
    green = Number(`0x${hex[3]}${hex[4]}`);
    blue = Number(`0x${hex[5]}${hex[6]}`);
  }

  return [red, green, blue];
}

/**
 * Converts RGB color string into RGB array.
 * @param {string} rgbString rgb(255,255,255) or rgb(0 0 0)
 * @returns {Array<number>} [255, 255, 255] or [0, 0, 0]
 */
function stringToRGB(rgbString: string): number[] {
  const rgbValues = rgbString.match(/\d+/g) || [];
  const [red = 0, green = 0, blue = 0] = rgbValues.map((value: string) =>
    Number(value),
  );
  return [red, green, blue];
}

/**
 * @param {string} backgroundColor hex or rgb string for the background.
 * @returns {Array<number>} an RGB array of the logo color e.g. [90, 49, 244]
 */
export function pickLogoColor(backgroundColor: string): number[] {
  const purpleBrand = [90, 49, 244];
  const whiteBrand = [255, 255, 255];

  // Default to white background
  let backgroundColorRgb = [255, 255, 255];

  if (backgroundColor.startsWith('#')) {
    backgroundColorRgb = hexToRGB(backgroundColor);
  } else if (backgroundColor.startsWith('rgb(')) {
    backgroundColorRgb = stringToRGB(backgroundColor);
  }

  return contrast(backgroundColorRgb, purpleBrand) >
    contrast(backgroundColorRgb, whiteBrand)
    ? purpleBrand
    : whiteBrand;
}

/**
 * Calculates the contrast between two colors.
 * @param {string} color1 hex or rgb string for the first color
 * @param {string} color2 hex or rgb string for the second color
 * @returns {number} the contrast between the two colors
 */
export function calculateContrast(color1: string, color2: string) {
  const color1Rgb = color1.startsWith('#')
    ? hexToRGB(color1)
    : stringToRGB(color1);
  const color2Rgb = color2.startsWith('#')
    ? hexToRGB(color2)
    : stringToRGB(color2);
  return contrast(color1Rgb, color2Rgb);
}

/**
 * The current algorithm for luminance can be found in the WCAG 2.0 documentation.
 * More specifically, the breakdown of the formula is the detailed in the first step of
 * the algorithm detailed in: https://www.w3.org/TR/WCAG20-TECHS/G17.html#G17-procedure
 * @param {number} red color.
 * @param {number} green color.
 * @param {number} blue color.
 * @returns {Array<number>} luminance.
 */
function luminance(red: number, green: number, blue: number): number {
  const luminancePerColor = [red, green, blue].map(function (color) {
    const colorRatio = color / 255;

    return colorRatio <= 0.03928
      ? colorRatio / 12.92
      : ((colorRatio + 0.055) / 1.055) ** 2.4;
  });

  return (
    luminancePerColor[0] * 0.2126 +
    luminancePerColor[1] * 0.7152 +
    luminancePerColor[2] * 0.0722
  );
}

/**
 * @param {Array<number>} rgb1 color.
 * @param {Array<number>} rgb2 color.
 * @returns {number} contrast between two colors
 */
function contrast(rgb1: number[], rgb2: number[]): number {
  const lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]);
  const lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]);
  const brightest = Math.max(lum1, lum2);
  const darkest = Math.min(lum1, lum2);

  return (brightest + 0.05) / (darkest + 0.05);
}

/**
 * @param {HTMLElement} mainElement The element rendering the background color
 * @returns {string} background color
 */
export function inferBackgroundColor(mainElement: HTMLElement) {
  // --color-background is used widely in themes to set the background color
  const backgroundColor = window
    .getComputedStyle(mainElement)
    .getPropertyValue('--color-background')
    ?.trim();

  if (backgroundColor) {
    return backgroundColor;
  }

  // climb the DOM tree to detect the background color
  for (const element of climbDomTree(mainElement)) {
    const backgroundColor = window
      .getComputedStyle(element)
      .getPropertyValue('background-color');
    if (backgroundColor && backgroundColor !== 'rgba(0, 0, 0, 0)') {
      return backgroundColor;
    }
  }

  // default to white
  return '#ffffff';
}
