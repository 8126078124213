/**
 * The default/standalone component is used for multiple flows. To disambiguate the flow for
 * analytics, we pass in an analytics context to the component. The "flow" here is specific
 * to the use-case, whereas the flow we pass to the authorize endpoint is more generic and
 * determines what (if any) side effects should happen after the user is authenticated.
 *
 * NOTE: The context name needs to be match those allow-listed in Pay.
 */
export enum DefaultComponentAnalyticsContext {
  Default = 'loginWithShop',
  CheckoutModal = 'loginWithShopCheckoutModal',
  ClassicCustomerAccounts = 'loginWithShopClassicCustomerAccounts',
  Prequal = 'loginWithShopPrequal',
  SelfServe = 'loginWithShopSelfServe',
  CheckoutExtension = 'loginWithShopCheckoutExtension',
  PaymentRequest = 'loginWithShopPaymentRequest',
}
export const ANALYTICS_CONTEXT_TO_FLOW_MAP = {
  [DefaultComponentAnalyticsContext.Default]: 'default',
  [DefaultComponentAnalyticsContext.SelfServe]: 'default',
  [DefaultComponentAnalyticsContext.ClassicCustomerAccounts]:
    'classic_customer_accounts',
  [DefaultComponentAnalyticsContext.Prequal]: 'shop_pay_installments_prequal',
  [DefaultComponentAnalyticsContext.PaymentRequest]: 'payment_request',
  [DefaultComponentAnalyticsContext.CheckoutExtension]: 'default',
  [DefaultComponentAnalyticsContext.CheckoutModal]: 'default',
};

const ELEMENT_PREFIX = 'shop-login-default';
export const IFRAME_CLASS_NAME = `${ELEMENT_PREFIX}-iframe`;
export const HEADER_CLASS_NAME = `${ELEMENT_PREFIX}-header`;
export const HEADER_CONTENTS_CONTAINER_CLASS_NAME = `${HEADER_CLASS_NAME}-contents-container`;
export const HEADER_TITLE_CLASS_NAME = `${HEADER_CLASS_NAME}-title`;
export const HEADER_DESCRIPTION_CLASS_NAME = `${HEADER_CLASS_NAME}-description`;
export const HEADER_DIVIDER_CLASS_NAME = `${HEADER_CLASS_NAME}-divider`;
export const FOOTER_CLASS_NAME = `${ELEMENT_PREFIX}-footer`;
export const FOOTER_CONTENT_CLASS_NAME = `${FOOTER_CLASS_NAME}-content`;
export const HIDDEN_ELEMENT_CLASS_NAME = `${ELEMENT_PREFIX}-hidden`;

export const SHOP_LOGIN_DEFAULT_HTML = `
  <template id="shop-login-default-landing">
        <style>
            shop-sheet-modal {
                line-height: 21px;
                font-style: normal;
            }

            iframe {
                width: 100%;
            }

            .${IFRAME_CLASS_NAME} {
                height: 0;
                border: none;
                margin: auto;
            }

            .${HEADER_CLASS_NAME} {
                padding-bottom: 16px;
                text-align: center;
                margin: 0 20px;
                -webkit-font-smoothing: antialiased;
            }

            .${HEADER_CLASS_NAME}-container {
                border: 0;
            }

            .${HEADER_DIVIDER_CLASS_NAME} {
                border-bottom: 1px solid #D9D9D9;
            }

            .${FOOTER_CLASS_NAME} {
                padding: 4px 12px 0;
                text-align: center;
                -webkit-font-smoothing: antialiased;
            }

            .${FOOTER_CONTENT_CLASS_NAME} {
                font-size: 12px;
                line-height: 1.4;
                font-family: -apple-system, BlinkMacSystemFont, San Francisco, Roboto, Segoe UI, Helvetica Neue, sans-serif;
                font-weight: 400;
                color: #000000b3;
                letter-spacing: 0px;
                padding: 8px 0 5px;
            }

            .${ELEMENT_PREFIX}-processing {
                margin: 0 20px 7px 20px;
            }

            .${ELEMENT_PREFIX}-processing-user {
                color: #0F1721;
                font-family: -apple-system, BlinkMacSystemFont, San Francisco, Roboto, Segoe UI, Helvetica Neue, sans-serif;
                font-size: 12px;
                letter-spacing: 0px;
                line-height: 21px;
                margin: 12px 0;
                min-height: 21px;
                overflow: hidden;
                text-align: left;
                text-overflow: ellipsis;
                text-rendering: optimizelegibility;
                white-space: nowrap;
                -webkit-font-smoothing: antialiased;
            }

            .${HIDDEN_ELEMENT_CLASS_NAME} {
                position: absolute;
                height: 0 !important;
                border: 0;
                padding: 0;
                margin: 0;
                visibility: hidden;
                overflow: hidden;
            }

            a {
                color: #5433EB;
                text-decoration: none;
            }

            a:active, a:focus, a:hover {
                color: #7b61f0;
                outline: none;
            }

            discount-code {
                margin: 0 20px;
            }
        </style>
        <shop-sheet-modal>
            <div class="${HEADER_CLASS_NAME} ${HEADER_DIVIDER_CLASS_NAME} ${HIDDEN_ELEMENT_CLASS_NAME}">
                <iframe sandbox="allow-same-origin" class="${HEADER_CLASS_NAME}-container"></iframe>
            </div>
            <div class="${ELEMENT_PREFIX}-content">
                <iframe class="${IFRAME_CLASS_NAME}" tabindex="0"></iframe>
                <div class="${ELEMENT_PREFIX}-processing ${ELEMENT_PREFIX}-hidden">
                    <div class="${ELEMENT_PREFIX}-processing-user"></div>
                    <div class="${ELEMENT_PREFIX}-processing-status"></div>
                </div>
            </div>
            <div class="${FOOTER_CLASS_NAME} ${HIDDEN_ELEMENT_CLASS_NAME}">
                <div class="${FOOTER_CONTENT_CLASS_NAME}"></div>
            </div>
        </shop-sheet-modal>
    </template>`;

export const HEADER_STYLE = `
    <style>
        body {
            font-family: -apple-system,BlinkMacSystemFont,San Francisco,Roboto,Segoe UI,Helvetica Neue,sans-serif !important;
            font-style: normal !important;
            text-align: center;
            margin: 0;
            -webkit-font-smoothing: antialiased;
            overflow: hidden;
        }

        .${HEADER_TITLE_CLASS_NAME} {
            font-size: 18px;
            font-weight: 700;
            line-height: 25px;
            letter-spacing: -0.2px;
            color: #000000;
            margin-bottom: 8px;
        }

        .${HEADER_DESCRIPTION_CLASS_NAME} {
            font-size: 14px;
            font-weight: 400;
            color: #0F1721;
            letter-spacing: 0px;
            white-space: pre-line;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    </style>
 `;

export const STORE_NAME_TEMPLATE = `$\{storeName}`;
