import {I18n} from 'common/translator/i18n';

import {
  createLoginButton,
  LoginWithShopButton,
} from '../components/login-with-shop-button';
import {
  ATTRIBUTE_ANALYTICS_TRACE_ID,
  ATTRIBUTE_ANCHOR_SELECTOR,
} from '../../../constants/loginButton';
import {
  DefaultComponentAnalyticsContext,
  FOOTER_CLASS_NAME,
  FOOTER_CONTENT_CLASS_NAME,
  HEADER_CLASS_NAME,
  HEADER_CONTENTS_CONTAINER_CLASS_NAME,
  HEADER_DESCRIPTION_CLASS_NAME,
  HEADER_DIVIDER_CLASS_NAME,
  HEADER_STYLE,
  HEADER_TITLE_CLASS_NAME,
  HIDDEN_ELEMENT_CLASS_NAME,
  IFRAME_CLASS_NAME,
  SHOP_LOGIN_DEFAULT_HTML,
  STORE_NAME_TEMPLATE,
} from '../../../constants/loginDefault';
import {ShopSheetModal} from '../../../common/shop-sheet-modal/shop-sheet-modal';
import {LoginWithShopSdkPageName} from '../../../common/analytics';
import {copyTemplateToDom, updateAttribute} from '../../../common/utils';
import MonorailTracker from '../analytics/DefaultComponentMonorailTracker';
import {
  AuthenticationLevel,
  ModalCustomizations,
  AuthorizeModalTextViewState,
  AuthorizeModalTextAction,
  AuthorizeModalTextStep,
} from '../../../types';
import {sanitizeModalText as sanitizeModalCustomization} from '../utils/modalCustomizations';
import {
  classicCustomerAccountsSignUpReducer,
  DEFAULT_STATE,
  defaultEmailAuthReducer,
  defaultPhoneAuthReducer,
  paymentRequestReducer,
} from '../utils/authorizeModalTextReducer';

export default class LoginDefaultView {
  private _rootElement: ShadowRoot;
  private _sheetModal!: ShopSheetModal;
  private _modalHeader: HTMLDivElement;
  private _headerIframe: HTMLIFrameElement | undefined;
  private _headerContentsContainer: HTMLDivElement | undefined;
  private _headerLogo: HTMLImageElement | undefined;
  private _headerTitle: HTMLElement | undefined;
  private _headerDescription: HTMLElement | undefined;
  private _footerElement: HTMLDivElement;
  private _footerContent: HTMLDivElement;

  private _iframe: HTMLIFrameElement;
  private _headerResizeObserver: ResizeObserver | undefined;

  private _modalTitle: string | undefined;
  private _modalDescription: string | undefined;
  private _modalLogoSrc: string | undefined;

  private _continueButton: LoginWithShopButton | undefined;

  private _monorailTracker: MonorailTracker | undefined;
  private _analyticsContext = DefaultComponentAnalyticsContext.Default;
  private _flowVersion = 'unspecified';
  private _authenticationLevel: AuthenticationLevel = AuthenticationLevel.Phone;
  private _flow: string | undefined;

  private _i18n: I18n | undefined;
  private _state: AuthorizeModalTextViewState = DEFAULT_STATE;

  constructor(rootElement: ShadowRoot, onClose: () => void) {
    this._rootElement = rootElement;

    copyTemplateToDom(
      SHOP_LOGIN_DEFAULT_HTML,
      'shop-login-default-landing',
      this._rootElement,
    );

    this._sheetModal = this._rootElement.querySelector('shop-sheet-modal')!;
    this._modalHeader = this._rootElement.querySelector(
      `.${HEADER_CLASS_NAME}`,
    )!;
    this._footerElement = this._rootElement.querySelector(
      `.${FOOTER_CLASS_NAME}`,
    )!;
    this._footerContent = this._rootElement.querySelector(
      `.${FOOTER_CONTENT_CLASS_NAME}`,
    )!;
    this._iframe = this._rootElement.querySelector(`.${IFRAME_CLASS_NAME}`)!;

    this._sheetModal.addEventListener('modalcloserequest', () => {
      this.closeAuthorizeModal();
      onClose();
    });

    updateAttribute(this._iframe, 'allow', 'publickey-credentials-get *');
  }

  setModalAnalyticsTraceId(analyticsTraceId: string): void {
    this._sheetModal.setAttribute(
      ATTRIBUTE_ANALYTICS_TRACE_ID,
      analyticsTraceId,
    );
  }

  async showModal() {
    await this._sheetModal.open();
    this.refreshHeaderIframeHeight();
  }

  async openAuthorizeModal() {
    this.showModal();
    this._monorailTracker?.trackPageImpression({
      page: LoginWithShopSdkPageName.AuthorizeModal,
    });
  }

  async closeAuthorizeModal() {
    if (this._sheetModal) {
      await this._sheetModal.close();
    }
    this._continueButton?.setFocused();
  }

  setContinueButtonVisible(isVisible: boolean) {
    if (!isVisible) {
      this._continueButton?.remove();
      this._continueButton = undefined;
      return;
    }

    if (!this._continueButton) {
      this._continueButton = createLoginButton();
      this._continueButton?.addEventListener('click', () => {
        this._monorailTracker?.trackLoginDefaultButtonClicked();
        this.openAuthorizeModal();
      });

      this._rootElement.appendChild(this._continueButton);
    }
  }

  setMonorailTracker(monorailTracker: MonorailTracker) {
    this._monorailTracker = monorailTracker;
  }

  setAnalyticsContext(analyticsContext: DefaultComponentAnalyticsContext) {
    this._analyticsContext = analyticsContext;
  }

  setFlowVersion(flowVersion: string) {
    this._flowVersion = flowVersion;
  }

  setAuthenticationLevel(authenticationLevel: AuthenticationLevel) {
    this._authenticationLevel = authenticationLevel;
  }

  setFlow(flow: string) {
    this._flow = flow;
  }

  getIframe(): HTMLIFrameElement {
    return this._iframe;
  }

  setAnchorSelector(anchorSelector: string) {
    this._sheetModal.setAttribute(ATTRIBUTE_ANCHOR_SELECTOR, anchorSelector);
  }

  setBrand(brand?: string) {
    if (brand) {
      this._sheetModal.setAttribute('modal-brand', brand);
    }
  }

  resizeIframe(height: number, width: number) {
    if (!this._iframe) return;

    this._iframe.style.height = `${height}px`;
    this._iframe.style.width = `${width}px`;
  }

  render() {
    const {configurable, headerVisible, headerDividerVisible} = this._state;

    this.setHeaderVisible(headerVisible);
    this.setHeaderDividerVisible(headerDividerVisible);

    if (configurable && this._modalLogoSrc) {
      this.updateHeaderLogo(this._modalLogoSrc);
    }

    this.renderHeaderTemplate();
    this.renderHeaderDescriptionTemplate();
    this.renderFooterContent();

    this.refreshHeaderIframeHeight();
  }

  dispatch(action: AuthorizeModalTextAction) {
    switch (this._analyticsContext) {
      case DefaultComponentAnalyticsContext.CheckoutExtension:
      case DefaultComponentAnalyticsContext.Default:
        this._state =
          this._authenticationLevel === AuthenticationLevel.Phone
            ? defaultPhoneAuthReducer(action, this._state)
            : defaultEmailAuthReducer(action, this._state);
        break;
      case DefaultComponentAnalyticsContext.ClassicCustomerAccounts:
        this._state =
          this._flowVersion === 'sign_up'
            ? classicCustomerAccountsSignUpReducer(action, this._state)
            : defaultPhoneAuthReducer(action, this._state);
        break;
      case DefaultComponentAnalyticsContext.PaymentRequest:
        this._state = paymentRequestReducer(action, this._state);
        break;
      default:
        this._state = DEFAULT_STATE;
        break;
    }

    this.render();
  }

  updateHeaderLogo(src: string) {
    if (!this._headerLogo) {
      this._headerLogo = document.createElement('img');
    }

    const shopSwirl =
      this._headerContentsContainer?.querySelector('shop-swirl');
    if (shopSwirl) {
      this._headerContentsContainer?.insertBefore(this._headerLogo, shopSwirl);
      shopSwirl?.remove();
    }

    this._headerLogo.src = src;
    this._headerLogo.style.maxHeight = '64px';
    this._headerLogo.style.width = 'auto';
  }

  renderHeaderTemplate() {
    const {configurable, headerTemplate, templateVariables} = this._state;
    let headerTitle = '';

    if (configurable && this._modalTitle) {
      headerTitle = this._modalTitle.replaceAll(
        STORE_NAME_TEMPLATE,
        templateVariables.clientName,
      );
    } else if (headerTemplate) {
      headerTitle =
        this._i18n?.translate(headerTemplate, templateVariables) || '';
    }

    if (typeof headerTitle === 'string') {
      this._headerTitle!.textContent = headerTitle;
    }
  }

  renderHeaderDescriptionTemplate() {
    const {configurable, descriptionTemplate, templateVariables} = this._state;
    let headerDescription = '';

    if (configurable && this._modalDescription) {
      headerDescription = this._modalDescription?.replaceAll(
        STORE_NAME_TEMPLATE,
        templateVariables.clientName,
      );
    } else if (descriptionTemplate) {
      headerDescription =
        this._i18n?.translate(descriptionTemplate, templateVariables) || '';
    }

    if (typeof headerDescription === 'string') {
      this._headerDescription!.textContent = headerDescription;
    }
  }

  renderFooterContent() {
    if (!this._i18n) return;

    const {step, templateVariables, userNameKnown, sessionDetected} =
      this._state;
    const {clientName, privacyPolicyUrl, termsOfServiceUrl} = templateVariables;
    let content = '';

    switch (step) {
      /**
       * For these steps, we need to render the legal text specific for the client
       * e.g., By continuing, your email address will be shared with...
       */
      case AuthorizeModalTextStep.PersonalizeConsent:
      case AuthorizeModalTextStep.EmailVerification:
      case AuthorizeModalTextStep.PhoneVerification:
      case AuthorizeModalTextStep.WebAuthnVerification:
      case AuthorizeModalTextStep.OneClick: {
        // Personalize Consent should only render legal text when a user cookie exists
        if (
          step === AuthorizeModalTextStep.PersonalizeConsent &&
          !sessionDetected
        ) {
          break;
        }

        // Shop Pay Commerce Component does not render legal text for these steps
        if (
          this._analyticsContext ===
          DefaultComponentAnalyticsContext.PaymentRequest
        ) {
          break;
        }

        const authorizeText = this._i18n.translate(
          userNameKnown
            ? 'legal.authorized_scopes.email_name'
            : 'legal.authorized_scopes.email',
          templateVariables,
        );

        /**
         * If a client does not have a privacy policy or terms of service, we should not render the client's
         * legal links.
         */
        if (!privacyPolicyUrl || !termsOfServiceUrl) {
          content = authorizeText;
          break;
        }

        const clientLegalText = this._i18n.translate(`legal.client`, {
          clientName,
          privacyPolicy: `<a href="${privacyPolicyUrl}" target="_blank">${this._i18n.translate(
            'legal.privacy_policy',
          )}</a>`,
          termsOfService: `<a href="${termsOfServiceUrl}" target="_blank">${this._i18n.translate(
            'legal.terms',
          )}</a>`,
        });

        content = `${authorizeText} ${clientLegalText}`;
        break;
      }

      /**
       * For the Sign Up step, a user must agree to Shop's terms and conditions and privacy policy
       */
      case AuthorizeModalTextStep.SignUp: {
        const shopTermsOfServiceLink = `<a href="https://shop.app/terms-of-service" target="_blank">${this._i18n.translate(
          'legal.terms_of_service',
        )}</a>`;
        const privacyPolicyLink = `<a href="https://www.shopify.com/legal/privacy/app-users" target="_blank">${this._i18n.translate(
          'legal.privacy_policy',
        )}</a>`;
        content = this._i18n.translate(`legal.shop`, {
          clientName,
          termsOfService: shopTermsOfServiceLink,
          privacyPolicy: privacyPolicyLink,
        });

        break;
      }
    }

    this._footerContent.innerHTML = content;
    this.setFooterVisible(Boolean(content));
  }

  setHeaderVisible(isVisible: boolean) {
    this._modalHeader.classList.toggle(HIDDEN_ELEMENT_CLASS_NAME, !isVisible);

    if (!this._headerIframe) {
      this._headerIframe = this._rootElement.querySelector(
        `.${HEADER_CLASS_NAME}-container`,
      )! as HTMLIFrameElement;
      const headerContainerIframeBody =
        this._headerIframe.contentDocument?.querySelector('body')!;
      headerContainerIframeBody.innerHTML = HEADER_STYLE;
      this._headerContentsContainer = document.createElement('div');
      this._headerContentsContainer.classList.add(
        HEADER_CONTENTS_CONTAINER_CLASS_NAME,
      );

      const headerLogo = document.createElement('shop-swirl');
      this._headerTitle = document.createElement('h2');
      this._headerTitle.classList.add(HEADER_TITLE_CLASS_NAME);
      this._headerDescription = document.createElement('div');
      this._headerDescription.classList.add(HEADER_DESCRIPTION_CLASS_NAME);
      this._headerContentsContainer.appendChild(headerLogo);
      this._headerContentsContainer.appendChild(this._headerTitle);
      this._headerContentsContainer.appendChild(this._headerDescription);

      if (this._flow === 'pop_up') {
        this._headerContentsContainer.style.display = 'flex';
        this._headerContentsContainer.style.flexDirection = 'column';
      }

      headerContainerIframeBody.appendChild(this._headerContentsContainer);

      if (window.ResizeObserver) {
        this._headerResizeObserver = new ResizeObserver(() => {
          this.refreshHeaderIframeHeight();
        });
        this._headerResizeObserver.observe(this._headerContentsContainer);
      } else {
        this.refreshHeaderIframeHeight();
      }
    }
  }

  /**
   * Resize iframe to match height of the modal header content
   */
  refreshHeaderIframeHeight() {
    const headerContainerIframeBody =
      this._headerIframe?.contentDocument?.querySelector('body')!;

    const heightStyle = `${
      this._headerContentsContainer?.getBoundingClientRect().height
    }px`;

    headerContainerIframeBody?.setAttribute('height', heightStyle);
    this._headerIframe?.setAttribute('height', heightStyle);
  }

  setHeaderDividerVisible(isVisible: boolean) {
    this._modalHeader.classList.toggle(HEADER_DIVIDER_CLASS_NAME, isVisible);
  }

  setFooterVisible(isVisible: boolean) {
    this._footerElement.classList.toggle(HIDDEN_ELEMENT_CLASS_NAME, !isVisible);
  }

  setCustomizedModalContent({
    modalTitle,
    modalDescription,
    modalLogo,
  }: ModalCustomizations) {
    if (modalTitle) {
      this._modalTitle = sanitizeModalCustomization(modalTitle);
    }
    if (modalDescription) {
      this._modalDescription = sanitizeModalCustomization(modalDescription);
    }
    if (modalLogo) {
      this._modalLogoSrc = sanitizeModalCustomization(modalLogo);
    }
  }

  getCustomizedModalContent() {
    return {
      modalTitle: this._modalTitle,
      modalDescription: this._modalDescription,
      modalLogo: this._modalLogoSrc,
    };
  }

  setTranslations(i18n: I18n) {
    this._i18n = i18n;
  }

  isModalCustomized() {
    return Boolean(
      this._modalTitle || this._modalDescription || this._modalLogoSrc,
    );
  }
}
