import {MessageEventSource} from './MessageEventSource';
import {isRootDomainMatch} from './utils';

export type MessageHandler<T> = (arg0: T) => void;

export default class MessageListener<T extends {type: string}> {
  private readonly _eventListener: (arg0: MessageEvent) => void;
  private readonly _eventDestination: Window;
  private _subscribers = new Set<MessageHandler<T>>();

  /**
   * @param {object} eventSource A reference to the element emitting the event which could be a window or an iframe.
   *    This allows multiple MessageListeners used simultaneously, ensuring only the corresponding eventHandler is
   *    called. Takes an iframe rather than an iframe's contentWindow allowing the listener to be initialized as soon
   *    as the iframe is created, before content is loaded, preventing any possibility of missed messages.
   * @param {object} eventOrigins The URLs from which a message would originate.
   * @param {Function} eventHandler Function to call when the event with the given source and origin occurs.
   * @param {Window} [eventDestination] The window object that is to listen for the event. Defaults to the global window
   *    object. However in the case of a page in an iframe, the message event will not bubble to the global window object,
   *    therefore this allows passing in the iframe's Window object.
   */
  constructor(
    eventSource: MessageEventSource,
    eventOrigins: string[],
    eventHandler: MessageHandler<T>,
    eventDestination: Window = window,
  ) {
    this._subscribers.add(eventHandler);

    this._eventListener = (event: MessageEvent) => {
      if (!eventSource.isSourceOf(event)) return;

      // Ignore events for which the root domain doesn't match that of the allowlist in eventOrigins
      if (
        !eventOrigins.some((origin) => isRootDomainMatch(origin, event.origin))
      ) {
        // eslint-disable-next-line no-console
        console.error('Origin mismatch for message event', event);
        return;
      }

      this._notify(event.data as T);
    };
    this._eventDestination = eventDestination;

    eventDestination.addEventListener('message', this._eventListener, false);
  }

  destroy() {
    this._eventDestination.removeEventListener(
      'message',
      this._eventListener,
      false,
    );
  }

  waitForMessage<TMessageType extends T['type']>(
    messageType: TMessageType,
    signal?: AbortSignal,
  ): Promise<Extract<T, {type: TMessageType}>> {
    let handler: MessageHandler<T>;

    const promise = new Promise<Extract<T, {type: TMessageType}>>(
      (resolve, reject) => {
        const handleAbort = () => {
          reject(new Error('Abort signal received'));
        };

        if (signal?.aborted) {
          handleAbort();
        }

        handler = (event) => {
          if (event.type === messageType) {
            signal?.removeEventListener('abort', handleAbort);
            resolve(event as Extract<T, {type: TMessageType}>);
          }
        };

        this._subscribers.add(handler);
        signal?.addEventListener('abort', handleAbort);
      },
    ).finally(() => {
      this._subscribers.delete(handler);
    });

    return promise;
  }

  private _notify(event: T) {
    this._subscribers.forEach((subscriber) => subscriber(event));
  }
}
