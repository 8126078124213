import {ReleaseStage} from './types';

export const RELEASE_STAGES: Record<string, ReleaseStage> = {
  dev: 'development',
  spin: 'spin',
  staging: 'staging',
  prod: 'production',
};

/*
This is the notifier object Bugsnag's own JS client sends. It is required to trigger Bugsnag
server-side parsing of the `useragent` string as well as client IP logging.
 */
export const BUGSNAG_NOTIFIER = {
  name: 'Bugsnag JavaScript',
  version: '7.13.2',
  url: 'https://github.com/bugsnag/bugsnag-js',
};
